import type { GetterTree, MutationTree } from 'vuex';
import type { ProductCategoriesQuery, NavigationTeasersQuery } from '@/lib/goliath/goliath.types';
import { getOffersByGroup, type Promotion } from '@/lib/offers-by-group';
import type { RootState } from '@/types/store-types';

export type NavigationTeaser = Promotion<{
  heading: string;
  subheading: string;
  alt?: string;
  customClass?: string;
  image: string;
  imagePosition?: string;
  link: {
    label: string;
    path?: string;
    theme?: string;
  };
  noPrice?: boolean;
  theme?: string;
  tileGroups: string[];
}>;

export type NavigationTeaserOffer = NonNullable<
  NonNullable<NonNullable<NavigationTeasersQuery['offers']>['entries']>[number]
>;

export type NavigationTeaserWithOffer = {
  offer?: NavigationTeaserOffer | undefined;
} & NavigationTeaser;

export type ListItem = {
  name: string;
  path: string;
};

export type Category = ListItem & {
  // this appears under "Kategorien"
  children?: ListItem[];
  mappingPath?: string; // used, when `path` differs from the category name in Goliath.
  // this appears under "Inhalte"
  listItems?: ListItem[];
  buttonLabel?: string;
  teasers?: NavigationTeaserWithOffer[];
};

export interface NavigationState {
  navigationItems: Category[];
}

interface NavigationGettersTypes {
  navigationItems: Category[];
}

export type NavigationGetterTree = {
  [P in keyof NavigationGettersTypes]: NavigationGettersTypes[P];
};

export type NavigationGetters = {
  [P in keyof NavigationGettersTypes]: (
    state: NavigationState,
    getters: NavigationGetterTree
  ) => NavigationGettersTypes[P];
};

export type GoliathCategory = NonNullable<
  NonNullable<ProductCategoriesQuery['productCategories']>['entries']
>[number];

export const TEASER_DATA: NavigationTeaser[] = [
  {
    articleNumber: '99935421',
    heading: 'Telekom T Phone 2',
    subheading: 'Einsteigerphone<br/>zum Spitzenpreis',
    image:
      '/content/content-images/navigation-teasers/441527d1af2dc04819deb98d1e364452/teaser-t-phone-2-bg@2x.jpg',
    alt: 'Telekom T Phone 2.',
    link: {
      label: 'Zum T Phone 2',
    },
    theme: 'light',
    tileGroups: ['smartphones-und-handys/smartphones'],
  },
  {
    articleNumber: '99935433',
    heading: 'Fairphone 5',
    subheading: 'Das nachhaltige Smartphone',
    image:
      '/content/content-images/navigation-teasers/5308d8134f05d96695f9855b91f75777/teaser-fairphone-bg@2x.jpg',
    alt: 'Fairphone 5.',
    link: {
      label: 'Zum Fairphone',
    },
    tileGroups: ['smartphones-und-handys/smartphones'],
  },
  {
    articleNumber: '6941948702417',
    heading: 'XIAOMI TV A 2025 Pro 75 Zoll',
    subheading: 'Für ein Immersives visuelles Erlebnis',
    image:
      '/content/content-images/navigation-teasers/517d5fcae770418a42ce22f263042e32/teaser-xiaomi-tv@2x.jpg',
    alt: 'XIAOMI TV A 2025.',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['tv'],
  },
  {
    articleNumber: '8806095593692',
    heading: 'Samsung 43 Zoll Crystal UHD 4K 2024 DU7170',
    subheading: 'PurColor Technologie für lebensechte<br/>Farben',
    image:
      '/content/content-images/navigation-teasers/65b3ed7601096de6a1b62183cb2b69b4/teaser-samsung-tv@2x.jpg',
    alt: 'Samsung 43 Zoll Crystal.',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['tv'],
  },

  {
    articleNumber: '99935296',
    heading: 'Apple 13" iPad Air (2024) Wi-Fi + 5G',
    subheading: 'Mit Liquid Retina Display',
    image:
      '/content/content-images/navigation-teasers/faeee6e21c10057b1b56cb13d43e9a5a/teaser-ipad@2x.jpg',
    alt: 'Apple 13" iPad Air.',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['tablets-und-zubehoer'],
  },
  {
    articleNumber: '6941812730355',
    heading: 'XIAOMI Pad 6 6/128GB Tablet',
    subheading: 'schlankes Design mit Metalloberfläche',
    image:
      '/content/content-images/navigation-teasers/85ea98f62f734db524f01ba60e6b1050/teaser-xiaomi-pad@2x.jpg',
    alt: 'XIAOMI Pad 6.',
    link: {
      label: 'Zum Angebot',
    },
    theme: 'light',
    tileGroups: ['tablets-und-zubehoer'],
  },
  {
    articleNumber: '99934414',
    heading: 'Samsung Galaxy Tab S9+ 5G',
    subheading: 'deine kreativen Ideen schnell zum Leben erweckt',
    image:
      '/content/content-images/navigation-teasers/1da7d953e4cf606c74f4997600c72caa/teaser-galaxy-tab-s9-5g@2x.jpg',
    alt: 'Samsung Galaxy Tab S9+.',
    link: {
      label: 'Zum Angebot',
    },
    theme: 'light',
    tileGroups: ['tablets-und-zubehoer'],
  },

  {
    articleNumber: '0840244600082',
    heading: 'Ein kompaktes Gerät mit vielen Funktionen',
    subheading: 'Smartwatch<br/>Google Pixel<br/>Watch WiFi',
    image:
      '/content/content-images/navigation-teasers/73466aafa028683b91a1c0b73123085d/google-pixel-watch@2x.jpg',
    alt: 'Google Pixel Watch.',
    link: {
      label: 'Zum Angebot',
    },
    theme: 'light',
    tileGroups: ['wearables'],
  },
  {
    articleNumber: '6941812764350',
    heading: 'XIAOMI Watch 2',
    subheading: 'Schlankes Design mit leichtem<br/>Gehäuse',
    image:
      '/content/content-images/navigation-teasers/102d74e4c00d39196daa6ffac1bd64c6/teaser-xiaomi-watch@2x.jpg',
    alt: 'XIAOMI Watch 2.',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['wearables'],
  },
  {
    articleNumber: '99935421',
    heading: 'XIAOMI Smart Band 8 Pro',
    subheading: 'dein Täglicher TrainignsBegleiter',
    image:
      '/content/content-images/navigation-teasers/d7b7ea1b0140814be447e8120c6df06d/teaser-xiaomi-watch-band-8-pro@2x.jpg',
    alt: 'XIAOMI Smart Band 8 Pro.',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['wearables'],
  },
  {
    articleNumber: '4251281669318',

    heading: 'Für das Herzstück eines jeden Gartens',
    subheading: 'Rasenträume werden wahr mit smart SILENO Systems!',
    image:
      '/content/content-images/navigation-teasers/3e46fc94e72a6afd5b6f12b5fef56cb5/sileno-maehroboter@2x.jpg',
    alt: 'Verschiedene Gardena Produkte: Mähroboter, smart Zwischenstecker, Wartungsset.',
    link: {
      label: 'Mähroboter entdecken',
      theme: 'transparent',
    },
    tileGroups: ['smart-home'],
    noPrice: true,
  },
  {
    heading: 'Smarte Gerätevielfalt',
    subheading: 'Für Mehr Komfort mit einem smarten Zuhause',
    image:
      '/content/content-images/navigation-teasers/ea369797b63bf01e2221f405df608faf/teaser-smart-home@2x.jpg',
    alt: 'Verschiedene Smart Home Geräte: Wiz LED-Lampe, Wiz Smart Plug, Eurotronic Thermostat.',
    link: {
      label: 'Alle Geräte im Überblick',
      path: '/smart-home',
      theme: 'transparent',
    },
    tileGroups: ['smart-home'],
  },
  {
    articleNumber: '4048945030085',
    heading: 'Teufel Rockster Cross Portable',
    subheading: 'Bluetooth-Stereo-Speaker der Spitzenklasse',
    customClass: 'teufel',
    image:
      '/content/content-images/navigation-teasers/ea4b7749549d742dd83697c1e6acc0d7/teaser-teufel-box@2x.jpg',
    alt: 'Ein Teufel Rockster Cross Portable hängt von der Schulter einer Person.',
    link: {
      label: 'Zum Angebot',
    },
    theme: 'light',
    tileGroups: ['zubehoer'],
  },
  {
    articleNumber: '0194644098018OT',
    heading: 'Anker 3-in-1 Cube with MagSafe',
    subheading: 'Der Multi-device&shy;schnelllader',
    image:
      '/content/content-images/navigation-teasers/8fdc12d56d65dab4cb500ced2901bf36/teaser-anker-cube@2x.jpg',
    alt: 'Anker 3-in-1 Cube with MagSafe.',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['zubehoer'],
  },
  {
    articleNumber: '6934177780554',
    heading: 'XIAOMI Smart Blender',
    subheading: 'Frische<br/>Smoothies Auf<br/>Knopfdruck',
    image:
      '/content/content-images/navigation-teasers/4fd65749a730cfc950eaf75ed449c4a4/teaser-xiaomi-blender@2x.jpg',
    alt: 'Ein XIAOMI Smart Blender voll mit Orangen, Erdbeeren und Melone.',
    link: {
      label: 'Zum Angebot',
    },
    tileGroups: ['zubehoer'],
  },
];

export const state = (): NavigationState => ({
  /**
   * We have to remove entries, sort them and add custom content to the goliath data.
   * We switch the logic. This Collection will be extended with the children from goliath.
   */
  navigationItems: [
    {
      name: 'Smartphones',
      path: 'smartphones-und-handys/smartphones',
      mappingPath: 'smartphones-und-handys',
      children: [],
    },
    {
      name: 'Tablets',
      path: 'tablets-und-zubehoer/tablets',
      mappingPath: 'tablets-und-zubehoer',
      children: [],
    },
    {
      name: 'Wearables',
      path: 'wearables',
      children: [],
    },
    {
      name: 'Smart Home',
      path: 'smart-home',
      children: [],
    },
    {
      name: 'TV',
      path: 'tv/fernseher',
      mappingPath: 'tv',
      children: [],
    },
    {
      name: 'Zubehör',
      path: 'zubehoer',
      children: [
        {
          name: 'Apple Original Zubehör',
          path: 'zubehoer/_apple', // In Goliath the path does NOT start with a slash and it will be added!
        },
      ],
    },
    {
      name: 'Themenwelten',
      path: 'themenwelten',
      listItems: [
        {
          name: 'Smart Home',
          path: 'smart-home-entdecken',
        },
        {
          name: 'agood company',
          path: 'a-good-company',
        },
        {
          name: 'Xiaomi',
          path: 'xiaomi',
        },
        {
          name: 'PanzerGlass',
          path: 'panzerglass',
        },
        {
          name: 'Google',
          path: 'google',
        },
        {
          name: 'Samsung',
          path: 'samsung',
        },
        {
          name: 'Anker',
          path: 'anker',
        },
      ],
    },
    {
      name: 'Aktionen',
      path: 'preisvorteile-sichern',
      children: [],
    },
  ],
});

export const mutations: MutationTree<NavigationState> = {
  SET_LIST_CHILDREN(state, params: { path: string; listItems: ListItem[] }) {
    const navigationItem = state.navigationItems.find(
      (item) => item.mappingPath === params.path || item.path === params.path
    );
    if (navigationItem?.children) {
      params.listItems.forEach((i) => {
        navigationItem.children?.push(i);
      });
    }
  },
  SET_TEASER_OFFERS(state, teaserOffers: NavigationTeaserWithOffer[]) {
    state.navigationItems.forEach((item) => {
      item.teasers = getOffersByGroup(teaserOffers, item.path, 2);
    });
  },
  SET_LIST_ITEMS(state, params: { path: string; listItems: ListItem[] }) {
    const navigationItem = state.navigationItems.find((item) => item.path === params.path);
    if (navigationItem) navigationItem.listItems = params.listItems;
  },
};

export const getters: GetterTree<NavigationState, RootState> & NavigationGetters = {
  navigationItems(state) {
    return state.navigationItems;
  },
};
