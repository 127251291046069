/* eslint-disable consistent-return */
import { defineNuxtRouteMiddleware, navigateTo } from '#imports';

// Nuxt Middleware Handler
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!to.fullPath) return;

  // "/tablets-und-surfsticks" -> "/tablets-und-zubehoer"
  if (to.fullPath.startsWith('/tablets-und-surfsticks')) {
    return navigateTo(to.fullPath.replace(/^\/tablets-und-surfsticks/, '/tablets-und-zubehoer'), {
      redirectCode: 301,
    });
  }

  // /smartwatches/zubehoer-fuer-smartwatches ->/wearables/smartwatch-zubehoer
  if (to.fullPath.startsWith('/smartwatches/zubehoer-fuer-smartwatches')) {
    return navigateTo(
      to.fullPath.replace(
        /^\/smartwatches\/zubehoer-fuer-smartwatches/,
        '/wearables/smartwatch-zubehoer'
      ),
      {
        redirectCode: 301,
      }
    );
  }

  // "/smartwatches" -> "/wearables"
  if (to.fullPath.startsWith('/smartwatches')) {
    return navigateTo(to.fullPath.replace(/^\/smartwatches/, '/wearables'), {
      redirectCode: 301,
    });
  }

  // /tv/tv -> /tv/fernseher
  if (to.fullPath.startsWith('/tv/tv/') || to.fullPath === '/tv/tv') {
    return navigateTo(to.fullPath.replace(/^\/tv\/tv/, '/tv/fernseher'), {
      redirectCode: 301,
    });
  }
});
