import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3817913c = () => interopDefault(import('../pages/a-good-company.vue' /* webpackChunkName: "pages/a-good-company" */))
const _e579db0a = () => interopDefault(import('../pages/anker/index.vue' /* webpackChunkName: "pages/anker/index" */))
const _087f0072 = () => interopDefault(import('../pages/bitte-kommen-sie-spaeter-wieder.vue' /* webpackChunkName: "pages/bitte-kommen-sie-spaeter-wieder" */))
const _472ec2c2 = () => interopDefault(import('../pages/google/index.vue' /* webpackChunkName: "pages/google/index" */))
const _f9a282e8 = () => interopDefault(import('../pages/handyrechnung-aktion.vue' /* webpackChunkName: "pages/handyrechnung-aktion" */))
const _2f886a0a = () => interopDefault(import('../pages/handyvergleich.vue' /* webpackChunkName: "pages/handyvergleich" */))
const _0d107fea = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _5d942a4d = () => interopDefault(import('../pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _10389a40 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _66f21743 = () => interopDefault(import('../pages/merkliste.vue' /* webpackChunkName: "pages/merkliste" */))
const _50b203bc = () => interopDefault(import('../pages/oster-angebote.vue' /* webpackChunkName: "pages/oster-angebote" */))
const _b39c9702 = () => interopDefault(import('../pages/panzerglass.vue' /* webpackChunkName: "pages/panzerglass" */))
const _1f37776a = () => interopDefault(import('../pages/preisvorteile-sichern.vue' /* webpackChunkName: "pages/preisvorteile-sichern" */))
const _5a297bf8 = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _7d600f9a = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _82ae092c = () => interopDefault(import('../pages/samsung/index.vue' /* webpackChunkName: "pages/samsung/index" */))
const _1699f9aa = () => interopDefault(import('../pages/samsung-cashback-aktion.vue' /* webpackChunkName: "pages/samsung-cashback-aktion" */))
const _7b360b76 = () => interopDefault(import('../pages/smart-home-entdecken.vue' /* webpackChunkName: "pages/smart-home-entdecken" */))
const _2a2d54e8 = () => interopDefault(import('../pages/stabiles-internet.vue' /* webpackChunkName: "pages/stabiles-internet" */))
const _efdaedb2 = () => interopDefault(import('../pages/themenwelten.vue' /* webpackChunkName: "pages/themenwelten" */))
const _61883ac4 = () => interopDefault(import('../pages/xiaomi.vue' /* webpackChunkName: "pages/xiaomi" */))
const _70383e9f = () => interopDefault(import('../pages/xiaomi-smartphones.vue' /* webpackChunkName: "pages/xiaomi-smartphones" */))
const _66c1c2b1 = () => interopDefault(import('../pages/xiaomi-zubehoer.vue' /* webpackChunkName: "pages/xiaomi-zubehoer" */))
const _7c3fb3d6 = () => interopDefault(import('../pages/zahlung-per-handyrechnung.vue' /* webpackChunkName: "pages/zahlung-per-handyrechnung" */))
const _0a0ae3b6 = () => interopDefault(import('../pages/anker/product-data.ts' /* webpackChunkName: "pages/anker/product-data" */))
const _0237c3a4 = () => interopDefault(import('../pages/google/product-data.js' /* webpackChunkName: "pages/google/product-data" */))
const _4da05a90 = () => interopDefault(import('../pages/hilfe/lieferstatus-und-retoure.vue' /* webpackChunkName: "pages/hilfe/lieferstatus-und-retoure" */))
const _0a0437ac = () => interopDefault(import('../pages/magenta-black-days/page-data.js' /* webpackChunkName: "pages/magenta-black-days/page-data" */))
const _3757c536 = () => interopDefault(import('../pages/samsung/product-data.ts' /* webpackChunkName: "pages/samsung/product-data" */))
const _3fc83b0a = () => interopDefault(import('../pages/shop/bestaetigung.vue' /* webpackChunkName: "pages/shop/bestaetigung" */))
const _5841cefb = () => interopDefault(import('../pages/shop/brodos-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/brodos-zahlungsverifizierung" */))
const _0c9d0f56 = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _6be698fb = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _0c1d373e = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _f23552d0 = () => interopDefault(import('../pages/shop/kundenformular/persoenliche-daten.vue' /* webpackChunkName: "pages/shop/kundenformular/persoenliche-daten" */))
const _4162f91f = () => interopDefault(import('../pages/shop/kundenformular/zahlungsart.vue' /* webpackChunkName: "pages/shop/kundenformular/zahlungsart" */))
const _4133bd10 = () => interopDefault(import('../pages/shop/telekom-bestaetigung.vue' /* webpackChunkName: "pages/shop/telekom-bestaetigung" */))
const _13ecf08a = () => interopDefault(import('../pages/shop/telekom-bezahlung-information.vue' /* webpackChunkName: "pages/shop/telekom-bezahlung-information" */))
const _7873ed75 = () => interopDefault(import('../pages/shop/telekom-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/telekom-zahlungsverifizierung" */))
const _79d3c62f = () => interopDefault(import('../pages/shop/warenkorb.vue' /* webpackChunkName: "pages/shop/warenkorb" */))
const _377f0fba = () => interopDefault(import('../pages/shop/zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/zahlungsverifizierung" */))
const _61ee4ceb = () => interopDefault(import('../pages/shop/zusammenfassung.vue' /* webpackChunkName: "pages/shop/zusammenfassung" */))
const _4dc662b6 = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _31a3ed4c = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _ffcf29fc = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _3cd25fee = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _78994c3f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _19e20748 = () => interopDefault(import('../pages/magenta-moments-2024/_slug.vue' /* webpackChunkName: "pages/magenta-moments-2024/_slug" */))
const _f4e4b032 = () => interopDefault(import('../special-pages/product-details.vue' /* webpackChunkName: "" */))
const _ffccdd50 = () => interopDefault(import('../special-pages/shop-list.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-good-company",
    component: _3817913c,
    name: "a-good-company"
  }, {
    path: "/anker",
    component: _e579db0a,
    name: "anker"
  }, {
    path: "/bitte-kommen-sie-spaeter-wieder",
    component: _087f0072,
    name: "bitte-kommen-sie-spaeter-wieder"
  }, {
    path: "/google",
    component: _472ec2c2,
    name: "google"
  }, {
    path: "/handyrechnung-aktion",
    component: _f9a282e8,
    name: "handyrechnung-aktion"
  }, {
    path: "/handyvergleich",
    component: _2f886a0a,
    name: "handyvergleich"
  }, {
    path: "/infos-zum-online-kauf",
    component: _0d107fea,
    name: "infos-zum-online-kauf"
  }, {
    path: "/kontakt",
    component: _5d942a4d,
    name: "kontakt"
  }, {
    path: "/maintenance",
    component: _10389a40,
    name: "maintenance"
  }, {
    path: "/merkliste",
    component: _66f21743,
    name: "merkliste"
  }, {
    path: "/oster-angebote",
    component: _50b203bc,
    name: "oster-angebote"
  }, {
    path: "/panzerglass",
    component: _b39c9702,
    name: "panzerglass"
  }, {
    path: "/preisvorteile-sichern",
    component: _1f37776a,
    name: "preisvorteile-sichern"
  }, {
    path: "/ratenzahlung",
    component: _5a297bf8,
    name: "ratenzahlung"
  }, {
    path: "/redirecting",
    component: _7d600f9a,
    name: "redirecting"
  }, {
    path: "/samsung",
    component: _82ae092c,
    name: "samsung"
  }, {
    path: "/samsung-cashback-aktion",
    component: _1699f9aa,
    name: "samsung-cashback-aktion"
  }, {
    path: "/smart-home-entdecken",
    component: _7b360b76,
    name: "smart-home-entdecken"
  }, {
    path: "/stabiles-internet",
    component: _2a2d54e8,
    name: "stabiles-internet"
  }, {
    path: "/themenwelten",
    component: _efdaedb2,
    name: "themenwelten"
  }, {
    path: "/xiaomi",
    component: _61883ac4,
    name: "xiaomi"
  }, {
    path: "/xiaomi-smartphones",
    component: _70383e9f,
    name: "xiaomi-smartphones"
  }, {
    path: "/xiaomi-zubehoer",
    component: _66c1c2b1,
    name: "xiaomi-zubehoer"
  }, {
    path: "/zahlung-per-handyrechnung",
    component: _7c3fb3d6,
    name: "zahlung-per-handyrechnung"
  }, {
    path: "/anker/product-data",
    component: _0a0ae3b6,
    name: "anker-product-data"
  }, {
    path: "/google/product-data",
    component: _0237c3a4,
    name: "google-product-data"
  }, {
    path: "/hilfe/lieferstatus-und-retoure",
    component: _4da05a90,
    name: "hilfe-lieferstatus-und-retoure"
  }, {
    path: "/magenta-black-days/page-data",
    component: _0a0437ac,
    name: "magenta-black-days-page-data"
  }, {
    path: "/samsung/product-data",
    component: _3757c536,
    name: "samsung-product-data"
  }, {
    path: "/shop/bestaetigung",
    component: _3fc83b0a,
    name: "shop-bestaetigung"
  }, {
    path: "/shop/brodos-zahlungsverifizierung",
    component: _5841cefb,
    name: "shop-brodos-zahlungsverifizierung"
  }, {
    path: "/shop/fehler",
    component: _0c9d0f56,
    name: "shop-fehler"
  }, {
    path: "/shop/kundenformular",
    component: _6be698fb,
    children: [{
      path: "",
      component: _0c1d373e,
      name: "shop-kundenformular"
    }, {
      path: "persoenliche-daten",
      component: _f23552d0,
      name: "shop-kundenformular-persoenliche-daten"
    }, {
      path: "zahlungsart",
      component: _4162f91f,
      name: "shop-kundenformular-zahlungsart"
    }]
  }, {
    path: "/shop/telekom-bestaetigung",
    component: _4133bd10,
    name: "shop-telekom-bestaetigung"
  }, {
    path: "/shop/telekom-bezahlung-information",
    component: _13ecf08a,
    name: "shop-telekom-bezahlung-information"
  }, {
    path: "/shop/telekom-zahlungsverifizierung",
    component: _7873ed75,
    name: "shop-telekom-zahlungsverifizierung"
  }, {
    path: "/shop/warenkorb",
    component: _79d3c62f,
    name: "shop-warenkorb"
  }, {
    path: "/shop/zahlungsverifizierung",
    component: _377f0fba,
    name: "shop-zahlungsverifizierung"
  }, {
    path: "/shop/zusammenfassung",
    component: _61ee4ceb,
    name: "shop-zusammenfassung"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _4dc662b6,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _31a3ed4c,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _ffcf29fc,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _3cd25fee,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/",
    component: _78994c3f,
    name: "index"
  }, {
    path: "/magenta-moments-2024/:slug?",
    component: _19e20748,
    name: "magenta-moments-2024-slug"
  }, {
    path: "/geraete/:slug?",
    component: _f4e4b032,
    name: "ProductDetails"
  }, {
    path: "/:config+",
    component: _ffccdd50,
    name: "ShopList"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
